import React, { useState, useRef } from 'react'
import { Link,  useParams } from 'react-router-dom';
import WhatsappButton from '../Components/WhatsappButton';
import emailjs from '@emailjs/browser';
import {   ToastContainer ,  toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Jobfile = () => {
    const form = useRef();
    let {title} = useParams();
    
    // const navigate = useNavigate();
    const[fname, setFname]=useState('');
        const[lname, setLname]= useState('');
        const[email, setEmail]=useState('');
        const[address, setAddress]=useState('');
        const[phone, setPhone]=useState('');
        const[cnic, setCnic]=useState('');
        const[job,setJob]=useState('');
       
        const handleSubmit = (e) => {
        
            e.preventDefault();
    
            let userData = {fname, lname, email, address, phone, job}
    
            if (userData.fname.length===0 || userData.lname.length===0 || userData.email.length===0 || userData.job.length===0 || userData.address.length===0 || userData.phone.length<=10)
             {
                // alert("error");
                toast.error("Please complete the form!", {
                    position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
                  });
               
            }
            else{
                emailjs
                .sendForm(
                    'service_u15fhmk',
                    'template_7v5yzpm',
                    form.current, {
                        publicKey: 'vblINXTeigQdG2T7d',
                    })
                    .then(
                        () => {
                          console.log('SUCCESS!');
                        },
                        (error) => {
                          console.log('FAILED...', error.text);
                        },
                      );
                      toast.success("Data send!", {
                        position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                      });
     // Reset the form after successful submission
    //  form.reset();
    setFname('')
    setLname('')
    setEmail('')
    setAddress('')
    setCnic('')
    setPhone('')
    setJob('')
            
        
        }
    };

    return ( 
    <div>
        <WhatsappButton/>
        <div className='container  py-2' style={{backgroundColor:"#F5F5F5"}}>
            <div className='row'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mt-5">
            <li className="breadcrumb-item ms-5" id='hed'><Link to="/">Home</Link></li>
            <li className="breadcrumb-item active" aria-current="page">{title}</li>
          </ol>
        </nav>
        </div>
            <div className='row justify-content-center mx-5 ' >
                <div className='col-md-12 mt-5 mx-auto'>
                    <h4 className='fw-bold mb-2'>Please fill the form of {title}</h4>
                    <h5 className='fw-bold mb-3'><span className='me-1'>at</span>: {title}</h5>
                    <form className='w-50'  ref={form} onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label fw-semibold">First name</label>
                            <input type="text" name='firstName' className="form-control" id="username" aria-label="First name" value={fname} onChange={(e)=>setFname(e.target.value)} />
                            {/* <div className="text-danger">{errors.firstName}</div> */}
                        </div>
                        
                        <div className="mb-3">
                            <label htmlFor="lastname" className="form-label fw-semibold">Last name</label>
                            <input type="text" name='lastName' className="form-control" id="lastname" aria-label="Last name" value={lname} onChange={(e)=>setLname(e.target.value)}  />
                            {/* <div className="text-danger">{errors.lastName}</div> */}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="email" className="form-label fw-semibold">Email</label>
                            <input type="email" name='email' className="form-control" id="email" aria-label="Email" value={email} onChange={(e)=>setEmail(e.target.value)} />
                            {/* <div className="text-danger">{errors.email}</div> */}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="jobTitle" className="form-label">Job Title</label>
                            <input type="text" name='job' className="form-control" id="jobTitle" aria-describedby="Job"  value={job} onChange={(e)=>setJob(e.target.value)} />
                            {/* {error && <div className="text-danger mt-2">{error}</div>} */}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="address" className="form-label fw-semibold">City, State</label>
                            <input type="text" name='address' className="form-control" id="address" aria-label="City, State" value={address} onChange={(e)=>setAddress(e.target.value)} />
                            {/* <div className="text-danger">{errors.address}</div> */}
                        </div>

                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label fw-semibold">Phone</label>
                            <input type="number" name='phone' className="form-control" id="phone" aria-label="Phone" value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                            {/* <div className="text-danger">{errors.phone}</div> */}
                        </div>
                        {/* Input fields for contact information */}
                        {/* ... */}
                        <div className="mb-3">
                            <label htmlFor="cnic" className="form-label fw-semibold">CNIC (optional)</label>
                            <input type="number" name='CNIC' className="form-control" id="cnic" aria-label="cnic" value={cnic} onChange={(e)=>setCnic(e.target.value)} />
                        </div>
                        <button type="submit" className="btn btn-dark btn-lg">Submit</button>
                    </form>
                </div>
                <div className='col-md-4 bg-info mt-5'></div>
            </div>
        </div>
        <ToastContainer />
        </div>
    );
}

export default Jobfile

import React from 'react'
import Layout from '../Components/Layout'
import WhatsappButton from './../Components/WhatsappButton';

const Contact = () => {
  return (
    <Layout>
      <WhatsappButton/>
      <div className='container-fluid color'>
         

   {/* Contact Form and info Section */}
   <div className="container my-5">
        <div className="row p-3" >
          {/* Contact Form Section */}

          <div className="col-lg-8 col-md-12 mb-4">
          <div className='text-center text-dark'>
            <h2 id='hed'>Contact Us</h2>
            <p className='text-secondary'>If you have a question about A3HMIR Global Corporation®  — or if you have a comment or idea you'd like to share — we'd love to hear from you! Please contact us.</p>
            <img
            src="./assests/3.png" alt="Testimonial" className="img-fluid" style={{borderRadius:"15px", width:"500px", height:"320px"}}
          />
        </div>
           
          </div>

          {/* info Section */}

          <div className="col-lg-4 col-md-4 mb-4  " >
       <div className='text-center mt-5 pt-4' >
        <h5>Call us anytime </h5>
        <p>+971-562388456</p>
       
        <br/>
        <h5>Come Visit Us</h5>
        <p> Dubai, United Arab Emirates</p>
        
        <br/>
        
        <h5>Send us a message</h5>
        <p> hi@a3hmirglobalcorporation.com</p>
        </div>   
       
          </div>
        </div>
      </div>
       
    {/* google map */}
    
<div className="container-fluid mt-5">
        <div className="row">
          <div className="col-12">
          <iframe class="contact-map"  title="Your Unique and Descriptive Title" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3612.4934786596577!2d55.374627724238934!3d25.119002034865687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6580a2b2d27d%3A0x4aea6ca8a0d4beff!2sA1%20building!5e0!3m2!1sen!2s!4v1710915209941!5m2!1sen!2s" width="100%" height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
</div>
      </div>
    </Layout>
  )
}

export default Contact

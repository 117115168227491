import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Contact from './Pages/Contact';
import Job from './Pages/Job';
import Cosmetics from './Pages/Cosmetics';
import Stationary from './Pages/Stationary';
import Paper from './Pages/Paper';
import Disposable from './Pages/Disposable';
import Clothes from './Pages/Clothes';
import JobPage from './Pages/JobPage';
import JobDetails from './Pages/JobDetails';
import JobForm from './Pages/JobForm';
import jobData from './Data/jobData';
function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/contact' element={<Contact/>}/>
        <Route path='/job' element={<Job/>}/>
        <Route path='/cosmetics' element={<Cosmetics/>}/>
        <Route path='/stationary' element={<Stationary/>}/>
        <Route path='/paper' element={<Paper/>}/>
        <Route path='/disposable' element={<Disposable/>}/>
        <Route path='/clothes' element={<Clothes/>}/>
        <Route path='/jobPage' element={<JobPage/>}/>
        {/* <Route path='/jobDetail' element={<JobDetails/>}/> */}
        {jobData.map((ele)=>(
             <Route
             key={ele.id}
             path={`/jobPlace${ele.id}`}
             element={<JobDetails imgurl={ele.imageUrl} title={ele.title} des={ele.description} JobType={ele.jobType} sal={ele.salary} proType={ele.projectType} location={ele.location}
             company={ele.company} skills={ele.skills} />}
           />
          ))}
        <Route path='/jobForm/:title' element={<JobForm/>}/>
        <Route path='/jobData' element={<jobData/>}/>
      </Routes>
    
    </Router>
  );
}

export default App;

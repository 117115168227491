import React from 'react'
import Layout from '../Components/Layout'
import WhatsappButton from '../Components/WhatsappButton'
import { paperProduct } from '../Data/productsApi'

const Paper = () => {
 
  return (
    <Layout>
      <div className='color'>
      <WhatsappButton/>
     {/* products images map */}

<div className='container'>
        <div className="row">
          {paperProduct.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div className="custom-card card"  id="card" style={{ width: '100%' }}>
                <img src={ele.image} className="custom-card-img-top card-img-top" alt="Course Preview" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* end map  */}
      </div>
    </Layout>
  )
}

export default Paper

const jobData = [

    {
        id: 1,
        mainId: 'Driver',
        title: 'Driver',
        description: 'A person who has UAE license and has a knowledge of Dubai. No limit age of experience and age.Company responsibility of food and residence. First come first apply.',
        location: ' Dubai, United Arab Emirates',
        company: 'A3HMIR Global Corporation',
        jobType: 'full-time',
        salary: '1500 - 2000',
        projectType: 'onside',
        skills: ['Managing space',' Negotiating intersections',' Managing speed',' Safe driving',' Car maintenance',' Customer service',' Effective communication'],
        Link: '/jobPlace1',
      },
      {
        id: 2,
        mainId: 'Trade Services',
        title: 'Trade Services',
        description: 'A person who has trade service knowledge of Dubai. Knowledge and experience has banks, documents and forwarding. Company responsibility of food and residence.',
        location: ' Dubai, United Arab Emirates',
        company: 'A3HMIR Global Corporation',
        jobType: 'full-time',
        salary: '2500 - 4000',
        projectType: 'onside',
        skills: ['Technical proficiency', ' Problem-solving skills',' Attention to detail',' Physical stamina',' Communication skills'],
        Link: '/jobPlace2',
      },
      {
        id: 3,
        mainId: 'Out Door Worker',
        title: 'Out Door Worker',
        description: 'A person who has knowledge and experience overall UAE. Company responsibility of food and residence.',
        location: ' Dubai, United Arab Emirates',
        company: 'A3HMIR Global Corporation',
        jobType: 'full-time',
        salary: '1500 - 2500',
        projectType: 'onside',
        skills: ['Responder', ' GPS',' Outdoor Education',' Natural History',' Customer Service'],
        Link: '/jobPlace3',
      },
      
]
export default jobData;
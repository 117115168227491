import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
<div className='container-fluid'>
<nav className="navbar top-fixed navbar-expand-lg navbar-light  ">
  <div className="container-fluid">
    <Link className="navbar-brand d-inline-block align-text-top " id='logo' to="/"><img src="./assests/A3HMIR.png" style={{height:'90px'}} class="img-fluid" alt="Responsive"/>  </Link>
    <button className="navbar-toggler bg-light"  type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
  <div className="collapse navbar-collapse" id="navbarSupportedContent">
    
    
  <ul className="navbar-nav    mb-2 mb-lg-0" >
    <li className="nav-item    me-1">
      <Link className="nav-link active fs-5 " to='/' aria-current="page" id='col' >Home</Link>
    </li>
    {/* Vertical Line */}
    <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item   ">
      <Link className="nav-link  fs-5" to='/about' id='col'>About</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item dropdown  ">
      <Link className="nav-link dropdown-toggle   fs-5"  to='/products' id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" >
        Products
      </Link>
      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
        <li><Link className="dropdown-item" to='/clothes'>Garment</Link></li>
        <li><Link className="dropdown-item" to='/cosmetics'>Cosmetics</Link></li>
        <li><Link className="dropdown-item" to='/stationary'>Stationary</Link></li>
        <li><Link className="dropdown-item" to='/paper'>Paper Printing</Link></li>
        <li><Link className="dropdown-item" to='/disposable'>Disposable</Link></li>       
      </ul>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-5 " to='/contact'  id='col' >Contact</Link>
    </li>
     {/* Vertical Line */}
     <div className="col-md-1 d-md-flex d-none align-items-center justify-content-center" >
            <div className="vertical-line" ></div>
          </div>
    <li className="nav-item ">
      <Link className="nav-link  fs-5 " to='/job' id='col'  >Career</Link>
    </li>
    
  </ul> </div>
  
     
    </div>
</nav>
</div>
    </>
  )
}

export default Navbar

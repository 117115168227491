import React, { useState } from 'react'
import Layout from '../Components/Layout'
import { Link } from 'react-router-dom'
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import WhatsappButton from '../Components/WhatsappButton';

const About = () => {
  const[counterOn,setCounteron]=useState(false);
  return (
    <Layout>
      <div className='color'>
      <WhatsappButton/>
      <br/><br/>
      {/* first section */}
  <div className='container mb-3 ' id='about'>
  <Link rel="stylesheet" to="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css" integrity="sha256-2XFplPlrFClt0bIdPgpz8H7ojnk10H69xRqd9+uTShA=" crossOrigin="anonymous" ></Link>
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-6 col-6">
            <div className="row">
              <div className="col-lg-12 col-md-12 mt-4 pt-2">
                <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                  <img src="./assests/about/about.png" className="img-fluid" alt="about"  />
                  <div className="img-overlay bg-dark" />
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end col*/}
          <div className="col-lg-6 col-md-6 col-6">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                  <img src="./assests/about/A1.jpg" className="img-fluid" alt="about" />
                  <div className="img-overlay bg-dark" />
                </div>
              </div>
              {/*end col*/}
              <div className="col-lg-12 col-md-12 mt-4 pt-2">
                <div className="card work-desk rounded border-0 shadow-lg overflow-hidden">
                  <img src="./assests/about/A2.jpeg" className="img-fluid" alt="about" />
                  <div className="img-overlay bg-dark" />
                </div>
              </div>
              {/*end col*/}
            </div>
            {/*end row*/}
          </div>
          {/*end col*/}
        </div>
        {/*end row*/}
      </div>
      {/*end col*/}
      <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2">
        <div className="section-title ml-lg-5">
          <h3 className="text-custom font-weight-normal mb-3" id='hed'>About Us</h3>
          <h4 className="title mb-4" id='hed'>
            Our mission is to <br />
            make your life easier.
          </h4>
          <p className="text-muted mb-5">
          In our paper disposable and cosmetics business, our mission is to redefine sustainability in the beauty industry. We are committed to offering innovative and eco-friendly paper-based disposable products that cater to the needs of modern consumers while minimizing environmental impact. Our vision extends beyond traditional beauty practices, as we aim to revolutionize the way disposable cosmetics are perceived and utilized.</p>
          <div className="row">
            <div className="col-lg-6 mt-4 pt-2">
            <div className="mt-4 pt-2 text-right">
                {/* <h6 className="ml-3 mb-0"> <Link to="/" className="btn btn-info-gradiant btn-md rounded-pill" id='btn'>Read More <i className="mdi mdi-chevron-right" /></Link></h6> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*end col*/}
    </div>
    {/*enr row*/}
  </div>
</div>

{/* second section */}

    <div className="py-5 service-17">
      <div className="container">
        {/* Row  */}
        <div className="card-group">
          {/* card  */}
          <div className="card mb-0">
            <div className="card-body text-center">
              <div className="p-3">
                <div className="my-3 display-5 text-info-gradiant fw-bolder">P</div>
                <h5 className="font-weight-medium">Paper Printing</h5>
                <p className='paragraph'>Paper, an essential medium of communication, bridges gaps between generations and cultures, preserving knowledge and creativity for posterity.</p>
              </div>
            </div>
          </div>
          {/* card  */}
          <div className="card mb-0">
            <img src="./assests/about/A3.png" alt="wrapkit" style={{height:"300px", width:"500px"}} />
          </div>
          {/* card  */}
          <div className="card mb-0">
            <div className="card-body text-center">
              <div className="p-3">
                <div className="my-3 display-5 text-info-gradiant fw-bolder">C</div>
                <h5 className="font-weight-medium">Cosmetics</h5>
                <p className='paragraph'>The cosmetics business thrives on the art of transformation, offering individuals the power to express themselves and enhance their natural beauty.</p>
              </div>
            </div>
          </div>
          {/* card  */}
        </div>
        <div className="card-group">
          {/* card  */}
          <div className="card mb-0">
            <img src="./assests/about/A5.png" alt="wrapkit" style={{height:"300px", width:"500px"}}/>
          </div>
          {/* card  */}
          <div className="card mb-0">
            <div className="card-body text-center">
              <div className="p-3">
                <div className="my-3 display-5 text-info-gradiant fw-bolder">D</div>
                <h5 className="font-weight-medium">Disposable</h5>
                <p className='paragraph'>In the disposable products business, efficiency meets responsibility as we prioritize convenience without compromising sustainability. </p>
              </div>
            </div>
          </div>
          {/* card  */}
          {/* card  */}
          <div className="card mb-0">
            <img src="./assests/about/A4.png" alt="wrapkit"style={{height:"300px", width:"500px"}} />
          </div>
        </div>
      </div>
    </div>

   {/* SECTION FOR REVIEWS  */}
   <div className="container mt-5">
      <div className="row">
        <div className="col-lg-6">
          <div className='text-center text-dark'>
            <h2>Testimonials</h2>
            <p>Some introductory text about your testimonials.</p>
            {/* <!-- Carousel wrapper --> */}
  <div id="carouselExampleIndicators" className="carousel slide">
  <div className="carousel-inner">
   
    <div className="carousel-item  active">
          <img className="rounded-circle shadow-1-strong mb-4" src="./assests/about/client1.jpg" alt="avatar" style={{width: 150}} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h5 className="mb-3">Anna Deynah</h5>
             
              <p className="text-dark">
                <i className="fas fa-quote-left pe-2" />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                nesciunt sint eligendi reprehenderit reiciendis.
              </p>
            </div>
          </div>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="far fa-star fa-sm" /></li>
          </ul>
        </div> 
    
    <div className="carousel-item">
    <img className="rounded-circle shadow-1-strong mb-4" src="./assests/about/client2.jpg" alt="avatar" style={{width: 150}} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h5 className="mb-3">John Doe</h5>
              
              <p className="text-dark">
                <i className="fas fa-quote-left pe-2" />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                nesciunt sint eligendi reprehenderit reiciendis, quibusdam illo, beatae quia
                fugit consequatur laudantium velit magnam error. Consectetur distinctio fugit
                doloremque.
              </p>
            </div>
          </div>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="far fa-star fa-sm" /></li>
          </ul>
        </div>
    <div className="carousel-item">
    <img className="rounded-circle shadow-1-strong mb-4" src="./assests/about/client3.jpg" alt="avatar" style={{width: 150}} />
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8">
              <h5 className="mb-3">John Doe</h5>
              
              <p className="text-dark">
                <i className="fas fa-quote-left pe-2" />
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus et deleniti
                nesciunt sint eligendi reprehenderit reiciendis.
              </p>
            </div>
          </div>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="fas fa-star fa-sm" /></li>
            <li><i className="far fa-star fa-sm" /></li>
          </ul>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true" />
    <span className="visually-hidden">Next</span>
  </button>
</div>

</div>

          <div>
</div>
        </div>
        <div className="col-lg-6">
          <img
            src="./assests/about/testimonial.png" alt="Testimonial" className="img-fluid"
          />
        </div>
      </div>
      </div>
        {/* SECTION FOR REVIEWS ENDS HERE */}

        {/* COUNTER ANIMATION START HERE */}
<div className="container">
<div className="row mt-5 ">
  <div className="col-lg-3" >
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #830570' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#000000', fontWeight:"bolder" }}>Projects Done</p>
      </div>
    </ScrollTrigger>
  </div>

  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #830570' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#000000', fontWeight:"bolder" }}>Clients Happy</p>
      </div>
    </ScrollTrigger>
  </div>

  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #830570' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#000000', fontWeight:"bolder" }}>Awards</p>
      </div>
    </ScrollTrigger>
  </div>

  <div className="col-lg-3">
    <ScrollTrigger onEnter={() => setCounteron(true)} onExit={() => setCounteron(false)}>
      <div className="counter" style={{ textAlign: 'center', marginBottom: '20px' }}>
        <h3 style={{ fontSize: '2em', color: ' #830570' }}>{counterOn && <CountUp start={0} end={100} duration={2} delay={0} />}</h3>
        <p style={{ fontSize: '1.2em', color: '#000000', fontWeight:"bolder" }}>Employees</p>
      </div>
    </ScrollTrigger>
  </div>
</div>
</div>
{/* COUNTER ANIMATION END HERE */}
</div>

    </Layout>
  )
}

export default About

import React from 'react'
import Layout from '../Components/Layout'
import WhatsappButton from '../Components/WhatsappButton'
import { kids, men, women } from '../Data/productsApi';

const Clothes = () => {
 
  return (
    <Layout>
      <div className='color'>
      <WhatsappButton/>
      <u> <h2 className='text-center p-4'>Category</h2></u>
     {/* kids images map */}

<div className='container'>
        <div className="row">
         <u> <h1>Kids</h1></u>
          {kids.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div className="custom-card card" id="card"  style={{ width: '100%' }}>
                <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* end map  */}

      {/* men images map */}

<div className='container'>
        <div className="row">
         <u> <h1>Men</h1></u>
          {men.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div className="custom-card card" id="card"  style={{ width: '100%' }}>
                <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* end map  */}

      {/* women images map */}

<div className='container'>
        <div className="row">
         <u> <h1>Women</h1></u>
          {women.map((ele, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 my-4">
              <div className="custom-card card" id="card"  style={{ width: '100%' }}>
                <img src={ele.image}  className="custom-card-img-top card-img-top" alt="Course Preview" />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* end map  */}
      </div>
    </Layout>
  )
}

export default Clothes

import React from 'react'
import { Link } from 'react-router-dom';

const WhatsappButton = () => {
    const buttonStyle = {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '1000',
      };
    
      const iconStyle = {
        
        fontSize: '24px',
       
       
      };
    
  return (
    <div style={buttonStyle}>
      <Link
        to="https://wa.me/971562388456" target="_blank" rel="noopener noreferrer"   className="btn btn-success" style={{borderRadius:"50%"}}
      >
        <i className="fab fa-whatsapp" style={iconStyle}></i>
        
      </Link>
    </div>
  )
}

export default WhatsappButton

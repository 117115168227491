import React from 'react'
import Layout from '../Components/Layout'
import WhatsappButton from '../Components/WhatsappButton'
import { Link } from 'react-router-dom'


const Job = () => {
  return (
    <Layout>
     <div className='color'> 
      <WhatsappButton/>

      {/* inner navbar */}

            <div class=" me-3 text-end">
           
              <Link to="/jobPage">
  <button type="button" className="btn btn-sm my-3" id="button">
    View Jobs
  </button>
</Link>
              
            </div>
      {/* end */}

    {/* hired section */}
      <div className='container-fluid mt-3'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */}
         
          <div className="col-md-6 mt-5" id="A1">
            <div className="my-text mt-5 ms-5">
              <h1 id='hed'>Let's get hired</h1>
              <p className='justify fs-5 text-secondary'>
              We want to help professionals reach the very pinnacle and stay there. For professionals who aspire perfection, we have made it easy for you by introducing skill-based hiring system, to help you attract the best talent from around the world.</p>
            </div>
          </div>
          

          {/* Right Side Column with Image */}
         
          <div className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5">
            <img src="./assests/job/1.png" className="img-fluid" alt="Mission"/>
          </div></div>
          
        </div>
      </div>

      {/* Stand out */}

      <div className='container-fluid mt-5'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */}
         
          <div className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5">
            <img src="./assests/job/10.png" alt="Mission" className="img-fluid" /></div>
          </div>
          

          {/* Right Side Column with Image */}
         
          <div className="col-md-6 mt-5" id="A1">
            <div className="my-text mt-5 ms-5">
              <h1 id='hed'>Stand out from the crowd</h1>
              <p className='justify fs-5 text-secondary'>
              Instead of spending hours on developing a CV, you can improve on your skills and get yourself hired. With the introduction of our novel skill-based hiring system, showcase your top skills and attract recruiters from around the world.</p>
            </div>
          </div>
          
        </div>
      </div>

      {/* elevating bussiness */}

      <div className='container-fluid mt-5'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */}
         
          <div className="col-md-6 mt-5" id="A1">
            <div className="my-text mt-5 ms-5">
              <h1 id='hed'>Elevating businesses</h1>
              <p className='justify fs-5 text-secondary'>
              One place to post your job, maintain your applicant data and interview your applicants. Maintain your team of interviewers and hiring managers in one place. Keep track of all your jobs and job applications of the applicant to maintain a timeline and career report.</p>
            </div>
          </div>
          

          {/* Right Side Column with Image */}
         
          <div className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5">
            <img src="./assests/job/3.png" alt="Mission" className="img-fluid"  /></div>
          </div>
          
        </div>
      </div>

      {/* right Job */}

      <div className='container-fluid mt-5'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */}
         
          <div className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5">
            <img src="./assests/job/4.png" alt="Mission" className="img-fluid" /></div>
          </div>
          

          {/* Right Side Column with Image */}
         
          <div className="col-md-6 mt-5" id="A1">
            <div className="my-text mt-5 ms-5">
              <h1 id='hed'>Find the right job</h1>
              <p className='justify fs-5 text-secondary'>
              Utilizing our refined search and filtering functionality, finding the perfect job has never been made so easy.</p>
            </div>
          </div>
          
        </div>
      </div>

      {/* dream Job */}

      <div className='container-fluid mt-5'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */}
         
          <div className="col-md-6 mt-5" id="A1">
            <div className="my-text mt-5 ms-5">
              <h1 id='hed'>Land your dream job</h1>
              <p className='justify fs-5 text-secondary'>
              In our newly designed professional profile page, add your strengths and be seen as an ideal candidate by your dream employer.</p>
            </div>
          </div>
          

          {/* Right Side Column with Image */}
         
          <div className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5">
            <img src="./assests/job/8.png" alt="Mission" className="img-fluid" /></div>
          </div>
          
        </div>
      </div>

      {/* interview */}

       <div className='container-fluid mt-5  mb-2'>
        <div className="row mx-auto">

          {/* Left Side Column with Text */}
         
          <div className="col-12 col-md-6 text-center ">
          <div className="my-text m-auto ms-5">
            <img src="./assests/job/5.png" alt="Mission" className="img-fluid" /></div>
          </div>
          

          {/* Right Side Column with Image */}
         
          <div className="col-md-6 mt-5" id="A1">
            <div className="my-text mt-5 ms-5">
              <h1 id='hed'>Effortless interviews</h1>
              <p className='justify fs-5 text-secondary'>
              Within-platform interview functionality is a redesigned concept where employers have the ability to get their favorite candidates interviewed by their own interviewers.</p>
            </div>
          </div>
        </div>
      </div>
      <br></br>
          </div>
    </Layout>
  )
}

export default Job
